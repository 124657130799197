<template>
        <!-- Le bouton -->
        <a href="" :class="'btn ' + className" :id="'button_' + action" @click.prevent="handle_action" v-if="name != '' && dropdown_name" style="width: 180px">
            <font-awesome-icon :icon="button_icon" :pulse="working"/> 
            <span v-if="allow_access" class="spanAction">{{ $t('action.'+name) }}</span>
            <span v-else class="spanAction" v-b-tooltip.top :title="$t('global.no_rights')">{{ $t('action.'+name) }}</span>
            <!-- L'éventuelle fenêtre modale de validation -->
            <ModalConfirm
                ref="modal"
                :v-if="confirm_message !== ''"
                :icon="icon"
                :text_title="(confirm_title === '' ? getTrad('modal.general.titre') : getTrad('modal.'+confirm_title))"
                :text_question="(confirm_question === '' ? getTrad('modal.general.question') : getTrad('modal.'+confirm_question))"
                :text_message="(confirm_message === '' ? getTrad('modal.general.message') : getTrad('modal.'+confirm_message))"
                :text_button_ok="(confirm_button_ok === '' ? getTrad('action.'+name) : getTrad('modal.'+confirm_button_ok))"
                :text_button_cancel="(confirm_button_cancel === '' ? getTrad('modal.general.annuler') : getTrad('modal.'+confirm_button_cancel))"
                :callback_validation="callback_valid_modal"
            />
        </a>
        <a href="" :class="'btn ' + className" :id="'button_' + action" @click.prevent="handle_action" v-else-if="name != ''">
            <font-awesome-icon :icon="button_icon" :pulse="working"/> 
            <span v-if="allow_access" class="spanAction">{{ $t('action.'+name) }}</span>
            <span v-else class="spanAction" v-b-tooltip.top :title="$t('global.no_rights')">{{ $t('action.'+name) }}</span>
            <!-- L'éventuelle fenêtre modale de validation -->
            <ModalConfirm
                ref="modal"
                :v-if="confirm_message !== ''"
                :icon="icon"
                :text_title="(confirm_title === '' ? getTrad('modal.general.titre') : getTrad('modal.'+confirm_title))"
                :text_question="(confirm_question === '' ? getTrad('modal.general.question') : getTrad('modal.'+confirm_question))"
                :text_message="(confirm_message === '' ? getTrad('modal.general.message') : getTrad('modal.'+confirm_message))"
                :text_button_ok="(confirm_button_ok === '' ? getTrad('action.'+name) : getTrad('modal.'+confirm_button_ok))"
                :text_button_cancel="(confirm_button_cancel === '' ? getTrad('modal.general.annuler') : getTrad('modal.'+confirm_button_cancel))"
                :callback_validation="callback_valid_modal"
                :processing="working"
            />
        </a>
</template>

<script>
    import ModalConfirm from "GroomyRoot/components/Modals/ModalConfirm"
    import TableAction from "@/mixins/TableAction.js"
    import Navigation from '@/mixins/Navigation.js'

	export default {
		name: 'ActionButton',
        mixins: [TableAction, Navigation],
        data(){
            return {
                working: false,
                events_tab: {
                    'TableAction::stopSpin': () => {
                        this.working = false
                    },
                }
            }
        },
		props: {
            name: String, /* Le texte du bouton */
            action: String, /* Le callback du bouton - dans TableActionMixin */
            icon: { type: Array, default: null }, /* Facultatif (défaut : vide) - L'icone du bouton */
            className: { type: String, default: 'btn-secondary', }, /* Facultatif (défaut : 'btn-secondary') - La ou les classe(s) CSS du bouton */
            selected_fn: { type: Function, default: () => (() => ([])) }, /* Facultatif (défaut : vide) - Un argument pour le callback */
            spin: {type: Boolean, default: false},
            confirm_message: { type: String, default : ''}, /* Facultatif (défaut : '') - Le message de confirmation (pas de confirmation si non spécifié) */
            confirm_title : { type: String, default : ''}, /* Facultatif - Le titre du message de confirmation */
            confirm_question : { type: String, default : ''}, /* Facultatif - La question du message de confirmation */
            confirm_button_ok : { type: String, default : ''}, /* Facultatif - Le texte du bouton Ok du message de confirmation */
            confirm_button_cancel : { type: String, default : ''}, /* Facultatif - Le texte du bouton Annuler du message de confirmation */
            button_action_event_only : { type: Boolean, default: false }, /* Facultatif - Remplace toutes les redirections du tableau vers des events bus */
            in_modal: { type: Boolean, default: false }, /* Factultatif - permet de savoir si on se trouve dans la modal en responsive */
			allow_access: { type: Boolean, default: true },
            dropdown_name: { type: String, default: '' }
        },
        components: {
            ModalConfirm,
        },
        methods: {
            /* Gestion de l'action du clic */
            async handle_action() {
				if(!this.allow_access) {
					return
				}

                if(typeof this[this.action] !== 'function') {
                    return false
                }

                if(this.confirm_message == '') { /* S'il n'y a aucune demande de confirmation avant action */

                    if(this.spin === true) {
                        this.working = true
                    }

                    const selected_items = await this.selected_fn()
                    return this[this.action](selected_items) /* Exécuter l'action */
                }
                else { /* Sinon */
                    this.$refs.modal.openModal() /* Ouvrir la fenêtre modale */
                }
            },
            async callback_valid_modal() {
                this.working = this.spin
                const selected_items = await this.selected_fn()
                this[this.action](selected_items)
                this.$refs.modal.closeModal()
                this.working = false
            }
        },
        computed: {
            button_icon(){
                if(this.working){
                    return ["far", "spinner"]
                }

                return this.icon
            }
        }
	}
</script>
